@import "../../theme";

// header
.header {
    position: relative;
    padding: 118px 0 82px;

    &:global(.jumbotron) {
        background: rgb(89, 77, 245);
        color: white;
        margin-bottom: 38px;
    }

    :global(.container) {
        position: relative;
    }

    .title {
        position: relative;
        z-index: 1;
        font-weight: 300;
        color: white;
        font-size: 36px;
    }

    @media(max-width: 1200px) {
        padding: 122px 0 78px;
    }

    @media(max-width: 600px) {
        padding: 92px 0 44px;

        .title {
            font-size: 27px;
        }

        .dot-grid {
            opacity: 0.3;

            &.left {
                opacity: 0.3;
            }
        }
    }
}

.nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 25px 30px;
    z-index: 4;
    width: 100%;
    transition-property: background-color, padding;
    transition-duration: 0.4s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.57, 1.02, 0.85, 1.05);
    will-change: background-color, padding;

    nav {
        display: flex;
        align-items: center;

        ul {
            li {
                position: relative;
                margin-left: $nav-margin;

                &:first-of-type {
                    margin-left: 0;
                }

                &:after {
                    content: " ";
                    display: block;
                    bottom: -3px;
                    height: 1px;
                    left: 0;
                    right: 0;
                    position: absolute;
                    transition: all .2s;
                }
        
                &:hover:after {
                    background-color: white;
                }
            }
        }

        a {
            position: relative;
            z-index: 1;
            padding: 8px 1px;
            color: white;
            font-size: 14px;
        }
    }

    .user-auth {
        margin-left: $nav-margin;
    }

    .logged-in-auth {
        display: flex;
        align-items: center;
        cursor: pointer;

        p {
            margin-left: 6px;
            color: white;
        }
    }

    .logo-link {
        display: flex;
        align-items: center;
        color: white;

        .full-logo {
            display: flex;
            align-items: center;
            font-weight: 500;
        }

        .logo-divider {
            width: 1px;
            height: 20px;
            margin: 0 10px;
            background-color: white;
        }

        .logo-title {
            font-size: 13px;
            font-weight: 400;
            white-space: nowrap;
        }

        &:hover .full-logo svg {
            -webkit-animation:spin 0.4s ease-in-out 1;
            -moz-animation:spin 0.4s ease-in-out 1;
            animation:spin 0.4s ease-in-out 1;
        }

        @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
        @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
        @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
    }

    .logo-text {
        color: white;
        &.docked {
            color: $color-font-dark;
        }
    }

    &.docked {
        background-color: #fff;
        padding: 15px 30px;
        box-shadow: 0 -1px 7px rgba(5, 10, 11, 0.4);

        nav a {
            color: $color-font-dark;

            &:hover {
                color: $color-primary;
            }
        }

        .logo-title {
            color: #050a0b;
        }

        .logo-divider {
            background-color: #050a0b;
        }

        .logged-in-auth {
            p {
                color: $color-font-dark;
            }
            svg {
                opacity: 0.8;

                > * {
                    fill: $color-font-dark;
                }
            }

            &:hover {
                p {
                    color: $color-primary;
                }
                svg {
                    opacity: 1;
                    
                    > * {
                        fill: $color-primary;
                    }
                }
            }
        }
    }

    .nav-btn {
        color: $color-primary;
        background-color: rgba(255,255,255,0.85);

        &.nav-btn-docked {
            background-color: $color-primary;

            a {
                color: white;
            }

            &:hover {
                background-color: darken($color-primary, 4);

                a {
                    color: white;
                }
            }
        }

        &:hover,
        &:active {
            background-color: white;
        }
    }

    @media(max-width: 992px) {
        padding: 25px 0;

        &.docked {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

// mobile nav
.mobile-nav-links {

    li {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
    }

    :global(.ant-badge) {
        position: absolute;
        top: 12px;
        right: 0;
    }
}

.mobile-drawer-btn {
    padding: 0;
}

.mobile-drawer-divider {
    display: inline-block;
    margin: 0 8px;
    height: 20px;
    width: 1px;
    background-color: #ddd;
}

// removing annoying orange outline
:global(.ant-drawer-close):focus {
    outline: none;
}

// background elements
.floating-elements {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
}

.dot-grid {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAOCAYAAAD0f5bSAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6M0U2QjcwOTI5RDFEMTFFOThCOTRFRjhEMjZDOTZFQjciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6M0U2QjcwOTE5RDFEMTFFOThCOTRFRjhEMjZDOTZFQjciIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNDE5MTI1RDkxOUMxMUU5QjgyNkUwRDM4MTY3RTY3QyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNDE5MTI1RTkxOUMxMUU5QjgyNkUwRDM4MTY3RTY3QyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjJzcQQAAAAxSURBVHjaYrx569FhBiBQU5W1ZSASMEFpRgYSAOP///8ZSAVMDGSAUU2jmlABQIABABOCBxaAvgspAAAAAElFTkSuQmCC");

    &.right {
        width: 50%;
        height: 60%;
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(50%, 0%);
    }

    &.left {
        opacity: 0.6;
        width: 50%;
        height: 30%;
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(-50%, 0%);
    }
}
.circle {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(60%, 50%) rotate(-45deg);

    .shape {
        width: 160px;
        height: 160px;
        border-width: 1px;
        border-color: #ffb202;
        border-style: solid;
        border-radius: 50%;
    }
}

.ant-badge {
    box-shadow: none;
}

:global(.ant-badge) {
    position: absolute;
    top: -4px;
    right: -6px;

    sup {
        height: 7px;
        width: 7px;
        min-width: 7px; // overwrites other min-width
        padding: 0;
        box-shadow: none;
        background-color: $color-badge-notification;

        > span {
            display: none;
        }
    }
}

// new features
:global(.new-features-page),
:global(.new-feature-page) {

    .header {
        background-color: $color-logo-light-blue;
    }

    .new-features-link:after {
        background-color: white;
    }

    .docked .new-features-link:after {
        background-color: $color-font-dark;
    }
}
