@import "../../theme";

.centered-card {
  width: 100%;
  font-weight: 500;
  text-align: center;
  padding: 2rem;

  .header {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
}

.help-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #d3d4d5;
  padding: 40px 0;
  margin-top: 40px;

  p {
    font-size: 13px;
    color: rgba(0,0,0,0.5);

    a {
      position: relative;
      color: rgba(0,0,0,0.5);
      display: inline-block;

      &:hover,
      &:focus {
        color: $color-primary;

        &:after {
          background-color: $color-primary;
        }
      }

      &:after {
        content: " ";
        display: block;
        bottom: 1px;
        height: 1px;
        left: 0;
        right: 0;
        position: absolute;
        transition: all .2s;
        background-color: rgba(0,0,0,0.3);
      }
    }
  }

  @media(max-width: 992px) {
    flex-flow: column;
    padding-right: 15px;
    padding-left: 15px;

    p {
      font-size: 12px;
    }

    > p {
      text-align: center;
    }

    .footer-main {
      margin-bottom: 20px;
    }
  }
}

.footer-main {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    margin-right: 10px;
  }
}

@media (max-width: 992px) {
  :global(.container) {
    max-width: 100% !important;
    padding-right: 6% !important;
    padding-left: 6% !important;
  }
}
