@import "../../theme";

.category-article {
    width: 100%;
    padding-right: 20px;

    hr {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
    }

    @media(max-width: 992px) {
        padding-right: 0;
    }

    > div:last-child hr {
        opacity: 0;
    }
}

.category-header {
    margin: 37px 0 13px;
    padding-bottom: 54px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    h1 {
        color: $color-primary;
        font-size: 46px;
        font-weight: 300;
    }
}
