@import "../../../theme";

.topic-name {
    padding-top: 46px;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 40px;
}

.topic-content {
    padding-bottom: 56px;

    .warning,
    .recommendation {
        border: 1px solid;
        padding: 20px 24px;
        border-radius: 4px;

        h3 {
            margin: 6px 0 14px;
        }
    }

    .warning {
        border-color: #ffa666;
        background-color: #fffbf4;
    }

    .recommendation {
        border-color: #67b9ff;
        background-color: #f4fbff;
    }

    h3 {
        font-size: 18px;
        font-weight: 500;
        color: rgba(0,0,0, 0.85);
        margin: 40px 0 14px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0,0,0, 0.65);
        line-height: 1.5;
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }

        b {
            font-weight: 600;
        }
    }

    ul, ol {
        margin: 12px 0 20px 30px;

        li {
            margin-top: 10px;
            font-size: 16px;
        }

    }

    ul {
        list-style: disc;

        li::marker {
            font-size: 20px;
        }
    }

    ol {
        list-style: decimal;
    }

    table {
        width: 100%;
        font-size: 13px;
        border: 1px solid #ccc;
        margin-top: 18px;

        tr {
            border-bottom: 1px solid #ccc;

            td:first-child {
                font-weight: 600;
                min-width: 220px;

                @media(max-width: 1200px) {
                    min-width: 16vw;
                }
            }

            td {
                min-width: 130px;

                @media(max-width: 1200px) {
                    min-width: 10vw;
                }
            }
        }

        thead {
            background-color: #f8f8f8;
        }

        td,
        th {
            padding: 8px 5px;

            &:first-child {
                padding-left: 10px;
            }
        }
    }

    .embedded-iframe-container {
        width: 100%;
        margin: 30px auto 38px;

        &:last-child {
            margin-bottom: 10px;
        }

        .embedded-iframe-wrapper {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 30px;
            height: 0;
            overflow: hidden;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
    }

    :last-child {
        margin-bottom: 0;
    }
}
