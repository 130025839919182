@import "../../theme";

.small {
    padding: 0 7px;
    font-size: 8px;
    font-weight: 600;
    &:global(.ant-btn) {
        transition: unset;
    }
}

.back-link {
    position: relative;
    z-index: 1;
    padding: 0 0 28px 2px;
    margin-left: -2px;
    background: white;
    
    svg {
        transition: all .3s;
    }

    a:hover {
        color: darken($color-primary, 14);

        svg {
            transform: translateX(-3px);

            path {
                fill: darken($color-primary, 14);
            }
        }
    }

    :global(.new-feature-page) & {
        a {
            color: $color-logo-light-blue;
    
            svg {
                path {
                    fill: $color-logo-light-blue;
                }
            }
        }
    }
}

.back-text {
    margin-left: 8px;
    font-weight: 500;
    font-size: 15px;
}
