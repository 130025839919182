@import "../../theme";

.features-home-content {
    max-width: 840px;
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    padding: 40px;
    background-color: white;
    box-shadow: 0 2px 8px rgba(54, 5, 90, 0.05);
}

:global(.new-features-page) {
    background-color: $color-gray-background;
}
