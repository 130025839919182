// app colors
$color-primary: #594dff;
$color-secondary: #0097ff;
$color-accent: #ffb202;
$color-badge-notification: #ff6161;
$color-gray-background: #f5f6f9;

$color-logo-dark-blue: #272e6e;
$color-logo-light-blue: #0097ff;

$color-bg: #fff;

// font colors
$color-font-light: #7c7f81;
$color-font-dark: #050a0b;

// spacing
$nav-margin: 26px;

:export {
    colorPrimary: $color-primary;
    colorSecondary: $color-secondary;
    colorLogoDarkBlue: $color-logo-dark-blue;
    colorLogoLightBlue: $color-logo-light-blue;
    colorBg: $color-bg;
    colorFontLight: $color-font-light;
    colorFontDark: $color-font-dark;
}
