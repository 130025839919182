@import "../../theme";

.homepage {
    background-color: $color-gray-background;
}

.category-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .category-card {
        width: calc(25% - 20px);
        margin: 0 10px 24px;
        min-width: 200px;
        box-shadow: 0 2px 8px rgba(54, 5, 90, 0.05);
        border-radius: 4px;
        padding: 22px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: white;

        h3 {
            font-size: 22px;
            margin-bottom: 12px;
        }

        a {
            color: $color-primary;
        }

        button {
            color: $color-primary;
            background-color: rgba(89, 77, 255, 0.1);
            border: none;
            box-shadow: none;
            text-shadow: none;
            font-weight: 500;
            font-size: 15px;

            &:hover {
                color: white;
                background-color: $color-primary;
            }
        }

        @media (max-width: 992px) {
            width: calc(33.33% - 20px);
        }

        @media (max-width: 768px) {
            width: calc(50% - 20px);
        }

        @media(max-width: 600px) {
            width: 100%;
        }
    }
}

.topic-link {
    color: $color-primary;
    position: relative;
    padding: 4px 8px;
    margin-left: -8px;
    display: inline-block;
    border-bottom: 1px solid transparent;
    transition: all .3s;
    font-weight: 500;
    font-size: 14px;

    &:hover {
        text-decoration: underline;
    }
}
