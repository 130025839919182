.row-spacing {
    margin-top: 15px;
}

.form {
    padding: 32px 32px 48px;
    box-shadow: 0 1px 4px rgba(#070b19, 0.12);
    background-color: #ffffff;
    border-radius: 4px;

    .sign-in-btn {
        width: 100%;
    }

    p {
        color: #cccccc;
        text-align: center;
        margin-top: 16px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
    }

    .title {
        margin-bottom: 20px;

        h1 {
            font-weight: 400;
            margin: 0;
            font-size: 28px;
        }
    }

    .sign-up {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
    }
}

.logo {
    margin-bottom: 24px;

    img {
        width: 160px;
    }
}

.background {
    background-color: #eff1f7;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
}
