@import "../../theme";

.content-nav {
    flex: 0 0 200px;
    width: 200px;
    margin-right: 76px;

    @media(max-width: 992px) {
        :global(.topic-page) & {
            margin-right: 40px;
        }
    }
}

.nav-title {
    position: relative;
    z-index: 1;
    padding: 0 0 16px 2px;
    margin-left: -2px;
    font-size: 22px;
    font-weight: 500;
    background: white;
}

.anchor:global(.ant-anchor-wrapper) {
    background-color: unset;

    :global(.ant-anchor-ink) {
        left: 8px;
    }

    :global(.ant-anchor-link) {
        left: 10px;
        padding: 0 0 0 22px;
        font-size: 13px;

        a {
            line-height: 1.25;
            white-space: inherit;
            padding: 6px 0;
            margin-bottom: 3px;
            color: rgba(0,0,0,0.4);
            font-weight: 500;

            &:hover,
            &:global(.ant-anchor-link-title-active) {
                color: $color-primary;
            }
        }

        &:last-child a {
            margin-bottom: 0;
        }
    }

    :global(.ant-anchor-ink-ball) {
        width: 2px;
        height: 28px;
        background-color: $color-primary;
        border: none;
        border-radius: 0;
        transform: translate(-50%,-36%);
        transition: top 0.3s ease-in-out;
    }
}

.nav-item {
    padding: 7px 0 7px 16px;
}

:global(.ant-back-top) {
    left: 70px;
    bottom: 30px;
}
