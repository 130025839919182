@import "../../theme";

.feature-content {
    max-width: 860px;
    margin: 4px auto 36px;

    h3 {
        font-size: 18px;
        font-weight: 500;
        color: rgba(0,0,0, 0.8);
        margin: 40px 0 14px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0,0,0, 0.55);
        line-height: 1.5;
        margin-bottom: 10px;

        &.description {
            color: $color-font-light;
            font-size: 15px;
            font-weight: 500;
            line-height: 1;
            margin: -16px 0 40px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        b {
            font-weight: 600;
        }
    }

    ul, ol {
        margin: 12px 0 20px 30px;

        li {
            margin-top: 10px;
            font-size: 16px;
        }

    }

    ul {
        list-style: disc;

        li::marker {
            font-size: 20px;
        }
    }

    ol {
        list-style: decimal;
    }

    :last-child {
        margin-bottom: 0;
    }

    @media(max-width: 992px) {
        padding-right: 0;
    }
}
