@import "../../../theme";

.load-more-list {
    min-height: 350px;
    margin-bottom: 25px;
}

.load-more-btn {
    text-align: left;
    margin-top: 32px;
    height: 32px;
    line-height: 32px;

    button {
        border: none;
        background-color: rgba($color-logo-light-blue, 0.1);
        color: $color-logo-light-blue;
        box-shadow: none;
        padding: 0 20px;
        height: 40px;
        font-size: 14px;
        font-weight: 500;

        &:hover,
        &:active,
        &:focus {
            color: white;
            background-color: $color-logo-light-blue;
        }
    }
}

:global(.ant-list-item) {
    border-bottom: none !important;
    padding: 0;
}

:global(.ant-list-item-meta-content) {
    position: relative;

    :global(.ant-list-item-meta-title) {
        margin: 38px 0 0 !important;
    
        a {
            display: inline-block;
            font-size: 24px;
            color: $color-logo-light-blue;
            padding: 20px;
            margin: -20px 0 0 -20px;
            line-height: 1.1;
            transition: all 0.3s;

            &:hover {
                transform: translateX(4px);
            }
        }
    }

    :global(.ant-list-item-meta-description) {
        position: absolute;
        top: 10px;
        font-weight: 600;
        font-size: 13px;
    }
}
